import http from '../utils/http'

/**
 *  @parms resquest 请求地址
 */
let resquest = "/dabo"

export default {
	//医生登录
	login(params) {
		return http.post(`${resquest}/login`, params)
	},
	//医生信息
	userInfo() {
		return http.get(`${resquest}/user`);
	},
	//医生修改密码
	change_pwd(params) {
		return http.put(`${resquest}/change_pwd`, params);
	},
	//医生登录日志
	userLoginLog(params) {
		return http.get(`${resquest}/login_log`, params);
	},
	//医生申请
	userApply(params) {
		return http.post(`${resquest}/apply`, params);
	},
	//退出登录
	logout(params) {
		return http.delete(`${resquest}/login_out/`, params);
	},

	// 病例库
	caseList(params) {
		return http.get(`${resquest}/get_case_library`, params);
	},
	caseDetail(params) {
		return http.get(`${resquest}/get_case_library_detail/` + params);
	},
	createCase(params) {
		return http.post(`${resquest}/create_case_library`, params);
	},
	copyCase(params) {
		return http.put(`${resquest}/copy_case/` + params);
	},
	updateCase(params) {
		return http.put(`${resquest}/update_case_library`, params);
	},
	delCase(params) {
		return http.delete(`${resquest}/delete_case_library/` + params);
	},



	// 病人库
	patientList(params) {
		return http.get(`${resquest}/get_patient`, params);
	},
	patientDetail(params) {
		return http.get(`${resquest}/get_patient_detail/` + params);
	},
	createPatient(params) {
		return http.post(`${resquest}/create_patient`, params);
	},
	updatePatient(params) {
		return http.put(`${resquest}/update_patient`, params);
	},
	delPatient(params) {
		return http.delete(`${resquest}/delete_patient/` + params);
	},

	//术前术后报告保存图片
	saveImage(params) {
		return http.post(`${resquest}/save_image`, params);
	},
	//获取术前术后报告
	getReport(params) {
		return http.get(`${resquest}/get_report`, params);
	},
	//保存患侧信息
	saveLeftRight(params) {
		return http.post(`${resquest}/save_left_right`, params);
	},

	//术前术后报告保存水平点
	savePoint(params) {
		return http.post(`${resquest}/save_point`, params)
	},
	//术前术后报告保存工具比例
	saveProportion(params) {
		return http.post(`${resquest}/save_proportion`, params)
	},
	//术前报告保存畸形参数
	saveParams(params) {
		return http.post(`${resquest}/save_params`, params)
	},
	// 获取术前术后报告数据
	getData(params) {
		return http.get(`${resquest}/get_report`, params);
	},
	//术前报告保存安装参数
	saveInstallBefore(params) {
		return http.post(`${resquest}/save_install_before`, params)
	},
	//术后报告保存安装参数
	saveInstallAfter(params) {
		return http.post(`${resquest}/save_install_after`, params)
	},
	//术后报告保存参考节段参数
	saveNear(params) {
		return http.post(`${resquest}/save_near `, params)
	},
	// 获前术后pdf
	getPdf(params) {
		return http.get(`${resquest}/get_pdf`, params);
	},

	//获取固环类型
	getSolidRing(params) {
		return http.get(`${resquest}/get_solid_ring`, params);
	},
	// 术后匹配立柱
	matchingPost(params) {
		return http.post(`${resquest}/check_post`, params)
	},
	//术后立柱选择和风险控制
	saveControl(params) {
		return http.post(`${resquest}/save_control`, params)
	},

	//base64转图片
	baseToImg(params) {
		return http.post(`${resquest}/baseToImg`, params)
	},
	// 系统配置
	config() {
		return http.get(`${resquest}/config/`);
	},

	// 短信
	sendSms(params) {
		return http.post(`${resquest}/send_sms/`, params);
  },
  
  // 术后报告复制
	copyAfterReport(params) {
		return http.post(`${resquest}/copy_after_report`, params)
  },
  
  	// 病例库
	pelvicList(params) {
		return http.get(`${resquest}/get_pelvic_library`, params);
  },
  pelvicDetail(params) {
		return http.get(`${resquest}/get_pelvic_library_detail/` + params);
	},
	createPelvic(params) {
		return http.post(`${resquest}/create_pelvic_library`, params);
  },
  //骨盆模块保存畸形参数
	savePelvicParams(params) {
		return http.post(`${resquest}/pelvic/save_params`, params)
	},
}