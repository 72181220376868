import Vue from 'vue'
import VueRouter from 'vue-router'

// import store from '@/state/store'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  //模拟本地的滚动行为
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})
router.beforeEach((routeTo, routeFrom, next) => {
  let token = localStorage.getItem('token');
  document.title = '大博计算机辅助外固定支架系统';
  //判断是否登录
  if ((routeTo.name !== 'login' && routeTo.name !== 'register') && !token) next({ name: 'login' })
  else next()
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // 如果定义了beforeResolve
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            //如果用户选择重定向
            if (args.length) {
              // 完成重定向。
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve();
            }
          })
        } else {
          resolve()
        }
      })
    }
  } catch (error) {
    return
  }
  next()
})

export default router
