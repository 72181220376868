import { Message } from 'element-ui';
import axios from 'axios'
import Vue from 'vue'
import routes from '../router/routes.js'

const service = axios.create({
  baseURL: Vue.prototype.$baseUrl,
  // baseURL: process.env.VUE_APP_URL,
  // 超时时间
  timeout: 10 * 1000
})
//请求拦截器
service.interceptors.request.use(config => {
  config.data = JSON.stringify(config.data); //数据转化
  config.headers = {
    'Content-Type': 'application/json;charset=UTF-8' //配置请求头
  }
  let lang = Vue.prototype.$cookieStore.getCookie('DB_LANG') //获取语言
  config.headers.lang = lang ? lang : 'zh-cn';
  const token = localStorage.getItem('token');//获取token
  if (token) {
    config.headers.token = token;
  }
  return config
}, error => {
  Promise.reject(error)
})

//响应拦截器
service.interceptors.response.use(response => {
  //接收到响应数据并成功后的一些共有的处理
  if (response.data.code == -1) {
    Message.error(response.data.msg)
    location.href = "http://" + window.location.host + "\/\#\/login"
    return;
  }
  return response.data
}, error => {
  /***** 接收到异常响应的处理开始 *****/
  console.log(error, 'error')
  // if (error && error.response) {
  //   // 公共错误处理

  //   switch (error.response.status) { //根据响应码具体处理
  //     case 400:
  //       error.message = '错误请求'
  //       break;
  //     case 401:
  //       error.message = '请重新登录'
  //       break;
  //     case 403:
  //       error.message = '拒绝访问'
  //       break;
  //     case 404:
  //       error.message = '请求错误,未找到该资源'
  //       window.location.href = "/NotFound"
  //       break;
  //     case 405:
  //       error.message = '请求方法未允许'
  //       break;
  //     case 408:
  //       error.message = '请求超时'
  //       break;
  //     case 500:
  //       error.message = '服务器端出错'
  //       break;
  //     case 501:
  //       error.message = '网络未实现'
  //       break;
  //     case 502:
  //       error.message = '网络错误'
  //       break;
  //     case 503:
  //       error.message = '服务不可用'
  //       break;
  //     case 504:
  //       error.message = '网络超时'
  //       break;
  //     case 505:
  //       error.message = 'http版本不支持该请求'
  //       break;
  //     default:
  //       error.message = `连接错误${error.response.status}`
  //   }
  // } else {
  //   // 超时处理
  //   if (JSON.stringify(error).includes('timeout')) {
  //     Message.error('服务器响应超时，请刷新当前页')
  //   }
  //   // error.message('连接服务器失败')
  // }

  //   Message.error(error.message)
  /***** 处理结束 *****/

  return Promise.resolve(error.response)
})

export default service