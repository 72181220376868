<template>
  <el-container style="height: 100%; border: 1px solid #eee">
    <el-aside width="200px" style="background-color: #364284">
      <div class="logo">
        <img src="../assets/img/logo_1.png" alt="logo" />
      </div>
      <el-menu
        :default-active="defaultActive"
        background-color="#364284"
        text-color="#fff"
        active-text-color="#364284"
        :router="true"
      >
        <el-menu-item index="/home" >
          <i class="el-icon-s-home"></i>
          <span slot="title">{{$t("nav.home")}}</span>
        </el-menu-item>
        <el-menu-item index="/case/list" >
          <i class="el-icon-menu"></i>
          <span slot="title">{{$t("nav.bank")}}</span>
        </el-menu-item>
       
        <el-menu-item index="/patient/list">
          <i class="el-icon-s-custom"></i>
          <span slot="title">{{$t("nav.patient")}}</span>
        </el-menu-item>
        <el-menu-item index="/pelvic/list">
          <i class="el-icon-s-custom"></i>
          <span slot="title">{{$t("nav.pelvic")}}</span>
        </el-menu-item>
		<el-menu-item index="/change">
          <i class="el-icon-setting"></i>
          <span slot="title">{{$t("nav.modify")}}</span>
        </el-menu-item>
		<el-menu-item index="/log">
          <i class="el-icon-s-unfold"></i>
          <span slot="title">{{$t("nav.log")}}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>

    <el-container>
      <el-header>
        <div class="input-search" v-if="defaultActive != '/case/list'">
          <input type="text" v-model="keyword" :placeholder="$t('nav.placeholder')" @keyup.enter="search" />
        </div>
        <div v-else></div>
        <div class="hand-lang-logout">
          <div class="lang-change">
            <span @click="handleLang('zh-cn')">CN</span>
            <el-divider direction="vertical"></el-divider>
            <span @click="handleLang('en')">EN</span>
          </div>
          <div class="btn-logout" @click="logout">{{$t("nav.logout")}}</div>
        </div>
      </el-header>
      <el-main style="height: 100%">
        <slot></slot>
      </el-main>
    </el-container>
  </el-container>
</template>


<script>
export default {
  props:{
    defaultActive: { //菜单激活项
			type: String,
			default: function (){
        return this.$route.path //当前路径
      }
		},
  },
  data() { 
    return {
      keyword: '',
      lang: this.$i18n.locale
    };
  },
  methods:{
    //切换语言
    handleLang(lang){
      this.lang = lang;
      this.$cookieStore.setCookie('DB_LANG', lang);
      this.$i18n.locale = lang;
    },
    search(){
      this.$router.push({path: '/case/list', query: {keyword: this.keyword}})
    },
    logout(){
      this.$confirm(this.$t('nav.logoutWarn'), this.$t('common.warn'), {
					confirmButtonText: this.$t('common.confirm'),
					cancelButtonText: this.$t('common.cancel'),
					type: 'warning'
				}).then(() => {
					this.$api.logout().then(res => {
							if (res.code) {
							 return	this.$router.push('/login')
							}
              this.$message.error(res.msg);
						})
					
				})
    }
  }
};
</script>

<style scoped>
.el-header {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-aside {
  color: #333;
  background: #364284;
}

.el-menu-item.is-active {
  background-color: #7ecdf6 !important;
}
.el-menu {
  border-right: none;
}
.el-main{
  padding: 0;
}
.logo {
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.logo img {
  width: 129px;
  height: 33px;
  display: inline-block;
}
.input-search {
  position: relative;
}
.input-search input {
  width: 181px;
  height: 35px;
  background: #f6f6f6;
  border: none;
  border-radius: 18px;
  padding-left: 60px;
  font-size: 15px;
  font-weight: 400;
}
.input-search:before {
  content: "";
  background: url(../assets/img/search_1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 50%;
  left: 28px;
  transform: translate(0%, -50%);
}
input:focus {
  outline: 0;
  border: 0;
}
.hand-lang-logout{
  display: flex;
}
.btn-logout {
  /*display: inline-block;*/
  padding: 8px 17px;
  padding-left: 37px;
  background: #f1f5f7;
  border: 1px solid #364284;
  border-radius: 14px;
  color: #364284;
  margin-right: 50px;
  position: relative;
}
.btn-logout::before {
  content: "";
  background: url(../assets/img/off.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translate(0%, -50%);
}
.btn-logout:hover {
  cursor: pointer;
}
.el-dropdown-link {
  cursor: pointer;
  color: #364284;
  margin-right: 20px;
}
.el-icon-arrow-down {
  font-size: 14px;
}
.lang-change{
  /*display: block;*/
  padding: 8px 17px;
  cursor: pointer;
}
</style>