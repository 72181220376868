// import store from '@/state/store'

export default [
	{
		path: '/',
		name: 'index',
		component: () => import('../views/Home'),
	},
	{
		path: '/home',
		name: 'home',
		component: () => import('../views/Home'),
		
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/account/Login.vue'),
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../views/account/Register.vue'),
	},
	{
		path: '/log',
		name: 'log',
		component: () => import('../views/account/Log.vue'),
	},
	{
		path: '/change',
		name: 'change',
		component: () => import('../views/account/Change.vue'),
	},
	{
		path: '/patient/list',
		name: 'patient-list',
		component: () => import('../views/patient/index.vue'),
	},
	{
		path: '/patient/create',
		name: 'patient-create',
		component: () => import('../views/patient/create.vue'),
	},
	{
		path: '/patient/edit',
		name: 'patient-edit',
		component: () => import('../views/patient/edit.vue'),
	},
	{
		path: '/case/list',
		name: 'case-list',
		component: () => import('../views/case/index.vue'),
	},
	{
		path: '/case/create',
		name: 'case-create',
		component: () => import('../views/case/create.vue'),
	},
	{
		path: '/case/details',
		name: 'case-details',
		component: () => import('../views/case/details.vue'),
	},
	{
		path: '/pre/upload',
		name: 'pre-upload',
		component: () => import('../views/preopreative/Upload.vue'),
	},
	{
		path: '/pre/scale',
		name: 'pre-scale',
		component: () => import('../views/preopreative/Scale.vue'),
	},
	{
		path: '/pre/level',
		name: 'pre-level',
		component: () => import('../views/preopreative/Level.vue'),
	},
	{
		path: '/pre/measure',
		name: 'pre-measure',
		component: () => import('../views/preopreative/Measure.vue'),
	},
	{
		path: '/pre/installation',
		name: 'pre-installation',
		component: () => import('../views/preopreative/Installation.vue'),
	},
	{
		path: '/pre/report',
		name: 'pre-report',
		component: () => import('../views/preopreative/Report.vue'),
	},
	{
		path: '/post/upload',
		name: 'post-upload',
		component: () => import('../views/postoperation/Upload.vue'),
	},
	{
		path: '/post/scale',
		name: 'post-scale',
		component: () => import('../views/postoperation/Scale.vue'),
	},
	{
		path: '/post/level',
		name: 'post-level',
		component: () => import('../views/postoperation/Level.vue'),
	},
	{
		path: '/post/measure',
		name: 'post-measure',
		component: () => import('../views/postoperation/Measure.vue'),
	},
	{
		path: '/post/installation',
		name: 'post-installation',
		component: () => import('../views/postoperation/Installation.vue'),
	},
	{
		path: '/post/stand',
		name: 'post-stand',
		component: () => import('../views/postoperation/Stand.vue'),
	},
	{
		path: '/post/report',
		name: 'post-report',
		component: () => import('../views/postoperation/Report.vue'),
  },
  {
		path: '/pelvic/list',
		name: 'pelvic-list',
		component: () => import('../views/pelvic/index.vue'),
  },
  {
		path: '/pelvic/create',
		name: 'pelvic-create',
		component: () => import('../views/pelvic/create.vue'),
  },
  {
		path: '/pelvic/report',
		name: 'pelvic-report',
		component: () => import('../views/pelvic/Report.vue'),
  },
  {
		path: '/pelvic/measure',
		name: 'pelvic-measure',
		component: () => import('../views/pelvic/Measure.vue'),
  },
  {
		path: '/pelvic/installation',
		name: 'pelvic-installation',
		component: () => import('../views/pelvic/Installation.vue'),
  },
  {
		path: '/pelvic/stand',
		name: 'pelvic-stand',
		component: () => import('../views/pelvic/Stand.vue'),
  },
  {
		path: '/pelvic/report',
		name: 'pelvic-report',
		component: () => import('../views/pelvic/Report.vue'),
  },
  {
		path: '/pelvic/details',
		name: 'pelvic-details',
		component: () => import('../views/pelvic/details.vue'),
  },
]
