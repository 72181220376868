<template>
    <div class="slide-box">
        <div v-if="direction == 'front'">
            <div class="l-msg" @click="change">{{slide === '0' ? $t('report.param3') : $t('report.param4') }}</div> 
            <div class="r-msg" @click="change">{{slide === '0' ? $t('report.param4') : $t('report.param3') }}</div> 
        </div>
        <div v-if="direction == 'side'">
            <div class="l-msg" @click="change">{{slide === '0' ? $t('report.param7')  : $t('report.param8') }}</div> 
            <div class="r-msg" @click="change">{{slide === '0' ? $t('report.param8')  : $t('report.param7') }}</div>  
        </div>
    </div>
</template>


<script>
export default {
  props: {
    slide: { 
        type: String,
        default: '0'
	},
    direction: {
        type: String,
        default: 'front'
    }
  },
  data() {
    return {
        nSlide: '0'
    };
  },

  methods:{
    change(){
        // this.nSlide  = this.nSlide == '0' ? '1' : '0';
        this.$emit('onChange')
        
    }
  }
};
</script>

<style scoped>
.l-msg, .r-msg{
    position: absolute;
    z-index: 99;
    font-size: 16px;
    color: #ffffff;
	cursor: pointer;

    padding: 8px 16px;
    border: 1px solid #a0a0a0;
    border-radius: 5px;
    background-color: #a0a0a0c2;
}
.l-msg{
    left: 30px;
    top: 20px;
}
.r-msg{
	right: 30px;
	top: 20px;
}
</style>