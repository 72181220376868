<template>
  <div id="app">
		<router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
export default {
  provide(){
    return{
      reload: this.reload
    }
  },
  data(){
    return{
      isRouterAlive: true,
    }
  },
  methods:{
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(function(){
        this.isRouterAlive = true;
      })
    }
  }
}
</script>
<style>
	@import url("./assets/css/common.css");
</style>
