<template>
  <div class="head">
    <div v-if="slide">
      {{ $t("report.slide") }}:{{
        slide === "0" ? $t("report.left") : $t("report.right")
      }}
    </div>
    <div v-else></div>
    <div>
      <el-dropdown @command="handleLang">
        <span class="el-dropdown-link">
          {{ lang == "zh-cn" ? "中文" : "English"
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="zh-cn">中文</el-dropdown-item>
          <el-dropdown-item command="en">English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div v-if="is_pelvic == 1" class="btn-case" @click="goPelvic">
        {{ $t("report.return") }}
      </div>
      <div v-else class="btn-case" @click="goCase">{{ $t("report.return") }}</div>
      <div class="btn-logout" @click="logout">{{ this.$t("nav.logout") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slide: {
      type: String,
      default: "",
    },
    is_pelvic: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      lang: this.$i18n.locale,
    };
  },
  methods: {
    //退出登录
    logout() {
      this.$confirm(this.$t("nav.logoutWarn"), this.$t("common.warn"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.logout().then((res) => {
          if (res.code) {
            return this.$router.push("/login");
          }
          this.$message.error(res.msg);
        });
      });
    },
    goCase() {
      this.$router.push("/case/list");
    },
    goPelvic() {
      this.$router.push("/pelvic/list");
    },
    //切换语言
    handleLang(lang) {
      this.lang = lang;
      this.$cookieStore.setCookie("DB_LANG", lang);
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style scoped>
.head {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-case {
  display: inline-block;
  padding: 8px 17px;
  padding-left: 37px;
  background: #f1f5f7;
  border: 1px solid #364284;
  border-radius: 14px;
  color: #364284;
  margin-right: 20px;
  position: relative;
}
.btn-case::before {
  content: "";
  background: url(../assets/img/icon_4.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translate(0%, -50%);
}
.btn-case:hover {
  cursor: pointer;
}
.el-dropdown-link {
  cursor: pointer;
  color: #364284;
  margin-right: 20px;
}
.el-icon-arrow-down {
  font-size: 14px;
}
</style>
